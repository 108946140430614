<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="500" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="simple-query">
      <a-form-model ref="queryForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form" labelAlign="right" :label-col="{span:12}" :wrapper-col="{span:12}">
<!--        <a-form-model-item label="项目名" prop="monitorpointnum">-->
<!--          <a-input v-model="queryParams.monitorpointnum"></a-input>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="底图名" prop="basemapname">
          <a-input v-model.trim="queryParams.basemapname" placeholder="请输入" style="width: 240px" :disabled="showType=='detail'"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目底图" prop="basemapurl">
          <a-upload
              :disabled="showType=='detail'"
              style="width: 240px"
              name="fileList"
              :headers="uploadHeaders"
              list-type="picture-card"
              :data="uploadBasemap"
              action="/upload"
              :file-list="basemapUrlList"
              @preview="handlePreview"
              @change="basemapUrlUploaded">
            <a-button v-if="showType=='add'||showType=='edit'"> <a-icon type="upload" /> 点击上传文件 </a-button>
            <div v-if="showType!='detail'" style="color: red;width: 230px">建议上传图像为16：9，透明度为40%的JGP格式图片，大小为5M以内</div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置人" prop="recordman">
          <a-input v-model.trim="queryParams.recordman" placeholder="" style="width: 240px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-show="false" label="设置时间" prop="recordtime">
          <a-date-picker show-time placeholder="选择时间" v-model="queryParams.recordtime" style="width: 240px;" :disabled="true"/>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-modal>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import {addBasemap, modifyBasemap} from "A/patrol";
import moment from "moment";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      previewVisible: false,
      previewImage: '',
      baseurl:'https://fda.u-lake.com/',
      monitorpointNameList: [],
      queryParams:{
        basemapid:'',
        monitorpointid:'',
        monitorpointnum:'',
        monitorpointname:'',
        address:'',
        basemapurl:'',
        basemapname:'',
        recordman:'',
        recordtime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      formRules: {
        basemapname: [{required: true, message: '请输入项目底图名'}],
      },
      basemapUrlList:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.queryParams.recordman=this.userInfo.username
    this.getMonitorpointName();
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item[0].monitorpointnum
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.queryParams) {
            if (key == 'recordtime') {
              if (this.showType == 'edit') {
                this.queryParams[key] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss") || '';
              } else {
                this.queryParams[key] = moment(this.detailData[key], 'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss") || '';
              }
            } else {
              this.queryParams[key] = this.detailData[key] || '';
            }
          }
          if (this.detailData.basemapurl) {
            let nameIndex = this.detailData.basemapurl.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.basemapurl.lastIndexOf('\\');
            }
            let fileName = this.detailData.basemapurl.substr(nameIndex + 1);
            this.basemapUrlList = [{
              uid: 'basemapuid',
              name: fileName,
              status: 'done',
              url: this.detailData.basemapurl,
            }];
          }
        })
      }
    },
    resetDatas() {
      this.$refs.queryForm.resetFields();
      this.basemapUrlList=null
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.queryForm.validate(valid => {
          if (valid) {
            this.showLoading();
            this.queryParams.recordtime = moment(this.queryParams.recordtime).format("YYYYMMDDHHmmss")
            this.queryParams.recordman=this.userInfo.username
            let params = {
              ...this.queryParams
            };
            if (this.showType == 'add') {
              addBasemap(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              modifyBasemap(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          }else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview() {
      this.previewImage = this.baseurl+this.queryParams.basemapurl;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('支持上传的图片类型为jpg/png格式!');
      }
      const isLt1M = file.size / 1024 /1024 < 2;
      if (!isLt1M) {
        this.$message.error('图片大小最大支持上传2MB!');
      }
      return isJpgOrPng && isLt1M;
    },
    basemapUrlUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.queryParams.basemapurl = file.response.urlPath;
        }
        return file;
      });
      this.basemapUrlList = fileList;
    },
  }
}
</script>